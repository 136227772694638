import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IOrderType } from 'src/app/appInterfaces/IOrderType';
import { IStore } from 'src/app/appInterfaces/IStore';
import { LoaderUtility } from 'src/app/appUtilities/loader.utility';
import { StoresService } from './stores.service';

@Injectable({
  providedIn: 'root'
})
export class StoreResolver {

  changeStoreClick = new Subject();
  storeList = new BehaviorSubject<IStore[]>(null);
  selectedStore = new BehaviorSubject<IStore>(null);
  selectedOrderType = new BehaviorSubject<IOrderType>(null);
  orderTypeList = new BehaviorSubject<IOrderType[]>(null);

  constructor(
    private _storeService: StoresService,
    private _loaderUtility: LoaderUtility
  ) {

  }



  /**
   * subscribe store list
   */
   subscribeStoreList() {
    if(this.storeList.value) {
      return
    }
    this._storeService.getAllStores().subscribe((res: IStore[]) => {
        this.setStoreList(res);
        // this.setSelectedStore(this.storeList.value[0] || null);
        this.getOrderTypeList();
    });
  }


  
  /**
   * Set Store list
   * @param storeList 
   */
   setStoreList(storeList: IStore[]) {
    this.storeList.next(storeList);
  }

  

  /**
   * set selected store
   * @param store 
   */
  public setSelectedStore(store: IStore) {
    this.selectedStore.next(store);
  }


  /**
   * clear Selected store
   */
  public clearSelectedStore(): void {
    this.selectedStore.next(null);
  }

  public getOrderTypeList() {
    if(!this.selectedStore.value) {
      return;
    }
    this._storeService.getAllOrderTypes(this.selectedStore.value?.id).subscribe(res => {
      if (res) {
        this.orderTypeList.next(res);
        this.setSelectedOrderType(this.orderTypeList.value[0]);
      }
    });
  }

  setSelectedOrderType(orderType: IOrderType) {
    this.selectedOrderType.next(orderType);
  }

/**
 * Clear storage
 */
  clear() {
    this.storeList.next(null);
    this.orderTypeList.next(null);
    this.selectedOrderType.next(null);
    this.selectedStore.next(null);
  }

  /**
   * Setup subscribe store list
   */
  async setupStore(set?:boolean) {
    if(this.storeList.value) {
      return
    }
    
    await this._loaderUtility.showLoader();
    this._storeService.getAllStores().subscribe(
      async (res: IStore[]) => {
        await this._loaderUtility.hideLoader();
        this.setStoreList(res);
        if(set) this.changeStoreClick.next();
      },
      async (err)=>{
        await this._loaderUtility.hideLoader();
      }
    );
  }
}
