import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { GlobalAuthResolver } from 'src/app/appServices/global-auth/global-auth.resolver';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private navCtrl: NavController
  ) { }

  canActivate(next: ActivatedRouteSnapshot): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    }
    this.navCtrl.navigateRoot('/auth');
    return false;
  }

  // canActivateChild(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   if(next.data?.roles > 0 && next.data?.roles.includes(this.globalAuth.role)) {
  //     return true
  //   }  
  //   return false;
  // }
}

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private navCtrl: NavController
  ) { }

  canActivate(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      this.navCtrl.navigateRoot('/');
      return false;
    }
    return true;
  }
}