import {
  HttpErrorResponse, HttpEvent, HttpHandler,
  HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogoutResolver } from 'src/app/appServices/logout/logout.resolver';
import { ToasterService } from 'src/app/appUtilities/toaster.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    public toastMessage: ToasterService,
    private _logoutResolver: LogoutResolver
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'Something went wrong.';

        if (error?.error === 'Unauthorized' || error.status === 401) {
          errorMessage = 'Invalid Credentials';
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          this._logoutResolver.logout();
          return throwError(errorMessage);
        }

        if (error?.status === 500 && error.error.details[0] === 'No Logged In User Found') {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          this._logoutResolver.logout();
          errorMessage = error.error?.details[0];
          this.toastMessage.errorToastWithClose('Session timeout, Please login again');
          return throwError(errorMessage);
        }

        if (error?.status === 500) {
          errorMessage = error.error?.details[0];
          return throwError(errorMessage);
        }

        return throwError(errorMessage);
      })
    );
  }
}