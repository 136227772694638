import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DateTimeUtility } from 'src/app/appUtilities/dateTime.utility';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';

@Injectable({
  providedIn: 'root'
})
export class KdsService {

  api = this.apiConfig.getBaseUrl;

  activeOrder = new BehaviorSubject(null);

  constructor(
    private apiConfig: HttpConfigService,
    private _dateTimeUtility: DateTimeUtility
    ) {
  }

  /**
   * get all routes
   * @returns 
   */
  getAllRoutes(): Observable<any> {
    return this.apiConfig.get(this.api + 'kds-route-config');
  }

  /**
   * get order status list
   * @returns 
   */
  getOrderStatusList(): Observable<any> {
    return this.apiConfig.get(this.api + 'orderStatus/getAll');
  }

  

  getOrdersByRoute(routeName, storeId): Observable<any> {
    const from = this._dateTimeUtility.getCurrentDate().getTime();
    const to = this._dateTimeUtility.getTimeSpanToDays(1).getTime();
    return this.apiConfig.get(this.api + 'order/' + from + '/' + to + '/' + routeName + '/' + '?storeId=' + storeId).pipe(take(1));
  }
  // https://main-api.preprod-tastio.com/app/v1/order/1704738600000/1704825000000/Cooks?storeId=1


  
  
}
