export enum PaymentMethods {
  CardReader = 'Card Reader',
  CashOnDelivery = 'Cash On Delivery',
  CardPayment = 'Card Payment',
}

export enum OrderStatus {
  Accepted = 'Accepted',
  Prepared = 'Prepared',
  Delivered = 'Delivered',
  Pending = 'Pending',
  OnTheWay = 'On The Way',
  Rejected = 'Rejected'
}
export enum OrderType {
  Delivery = 'Delivery',
}

export enum OrderCardColor {
  low = 'green',
  medium = 'yellow',
  high = 'red',
}
