import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  playSecond = new BehaviorSubject(false);
  constructor() { 
  }

  audioStream = [
    { 
      name: 'notifyBeep',
      audio : new Audio("/assets/audio/notify-beep.mp3")
    }
  ];
 

  playAudio(audioName){
    this.stopAll();
    let audio;
    audio = this.audioStream.find(n => n.name == audioName).audio;
    audio.load();
    audio.play();
    return audio.addEventListener;
    // audio.addEventListener("ended", ()=>{
    //   alert(0)
    // })
  }

  stopAll(){
    this.playSecond.next(false);
    this.audioStream.map(a =>{
      a.audio.pause();
    });
  }
  
}
