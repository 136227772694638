import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ActionSheetController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { GlobalAuthResolver } from 'src/app/appServices/global-auth/global-auth.resolver';
import { SettingsResolver } from 'src/app/appServices/settings/settings.resolver';
import { ThemeService } from 'src/app/appServices/theme.service';
import { BreakpointService } from 'src/app/appUtilities/breakpoint.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private platform: Platform,
    private _breakpointService: BreakpointService,
    private _globalAuthResolver: GlobalAuthResolver,
    private _settingsResolver: SettingsResolver,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private actionSheetCtrl: ActionSheetController,
    private themeService: ThemeService,
    private router:Router
  ) {}

  initializeApp() {
    this.platform.ready().then(() => {
      this.themeService.detectSystemTheme();
      this._settingsResolver.setRootFontSize();
      this._breakpointService.onResize(this.platform.width());
      this.checkLogin();
    });
    this.initRouterEvents();
  }


  
  /**
   * Init Router Events
   */
   initRouterEvents() {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          // this.checkUrlToSkipStandbyScreen();
          this.dismissModalAndPopover();
        }
      }
    );
  }





  
  async dismissModalAndPopover() {
    const isPopOverOpened = await this.popoverCtrl.getTop();
    const isActionSheetOpened = await this.actionSheetCtrl.getTop();
    const isModalOpened = await this.modalCtrl.getTop();
   
    if(isPopOverOpened){
      this.popoverCtrl.dismiss();
    }

    if(isActionSheetOpened){
      this.actionSheetCtrl.dismiss();
    }

    if(isModalOpened){
      this.modalCtrl.dismiss({
        'dismissed': true
      });
    }

  }




  /**
   * check login
   */
  checkLogin() {
    const token = localStorage.getItem('token');
    if (token) {
      this._globalAuthResolver.loggedIn.next(true);
    }
  }
}
