import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateTimeUtility {
  currentTime = new BehaviorSubject<any>(null);
  diffSub:Subscription;
  diffTimer = timer(1000, 1000);

  constructor() {
    this.createLiveCurrentTime();
  }

  createLiveCurrentTime() {
    this.diffSub = this.diffTimer.subscribe((res) => {
      this.currentTime.next(new Date().getTime());
    });
  }



  /**
   * Accepts time stamp and returns the time difference between timeStamp and current time in seconds
   *
   * @param timeStamp
   * @returns seconds
   */
  getTimeDifferenceInSeconds(timeStamp: number) {
    return ((Date.now() - timeStamp) / (1000 * 3600)) * 60 * 60;
  }

  /**
   *
   * @param seconds
   * @returns string, formatted second into h:mm:ss
   */
  formatSeconds(seconds: number) {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  }

  // format date in typescript
  getFormatedDate(date: Date, format: string) {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }


  getCurrentDate(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  getTimeSpanToDays(days: number): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const calculatePreviousDate = new Date(today);
    calculatePreviousDate.setDate(calculatePreviousDate.getDate() + days);
    return calculatePreviousDate;
  }
  
}
