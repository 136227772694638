import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class LoaderUtility {
  private loader: any;
  constructor(public loadingCtrl: LoadingController) {
  }

  async showLoader() { //call this fn to show loader
    this.loader = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    this.loader.present();
  }

  async showLoaderWithMsg(msg: string) { //call this fn to show loader
    this.loader = await this.loadingCtrl.create({
      message: msg
    });
    this.loader.present();
  }


  async hideLoader() { //call this fn to hide loader
    await this.loader?.dismiss();
    
    const isloaderExist = await this.loadingCtrl.getTop();
    if(isloaderExist) this.loadingCtrl?.dismiss();
  }

}
