import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SettingsResolver {
  baseSize: number = 10;

  rootFontSize = new BehaviorSubject<number>(this.baseSize);
  htmlRoot:HTMLElement = <HTMLElement> document.getElementsByTagName("html")[0];
  
  constructor(
  ) {
    this.getRootFontSizeFromLocalStorage()
  }

  restoreFontSize(){
    this.rootFontSize.next(this.baseSize);
    localStorage.setItem('rootFontSize', JSON.stringify(this.baseSize));
  }


  updateRootFontSize(fontSize:number){
    this.rootFontSize.next(fontSize);
    localStorage.setItem('rootFontSize', JSON.stringify(fontSize));
  }
  

  setRootFontSize(){
    this.rootFontSize.subscribe(rfs =>{
      if (this.htmlRoot != null) {
        this.htmlRoot.style.fontSize = rfs + 'px';
      }
    })
  }


  getRootFontSizeFromLocalStorage(){
    const rootFontSize= JSON.parse(localStorage.getItem('rootFontSize'));
    this.rootFontSize.next(rootFontSize);
  }
}
