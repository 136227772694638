import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBasicInfo } from 'src/app/appInterfaces/IBasicInfo';
import { TenantResolver } from '../tenant/tenant.resolver';
import { RestaurantInfoService } from './restaurant-info.service';

@Injectable({
    providedIn: 'root'
})
export class RestaurantInfoResolver {
    restaurantBasicInfo = new BehaviorSubject<IBasicInfo>(null);
    constructor(
        private restaurantInfoService: RestaurantInfoService,
        private _tenantResolver: TenantResolver
    ) { 
        this.getBasicInfo();
    }

    /**
     * get basic info
     */
    public getBasicInfo() {
        if (!this._tenantResolver.getTenantName()) {
            return;
        }
        if (!localStorage.getItem("basic-info")) {
            this.setRestaurantBasicInfoFromApi();
            
        } else {
            this.restaurantBasicInfo.next(JSON.parse(localStorage.getItem("basic-info")));
        }
    }


    setRestaurantBasicInfoFromApi() {
        this.restaurantInfoService.getBasicInfo().subscribe(res => {
            localStorage.setItem("basic-info", JSON.stringify(res));
            this.restaurantBasicInfo.next(res);
        })
    }


    /**
     * clear basic info
     */
    public clearBasicInfo() {
        this.restaurantBasicInfo.next(null);
        localStorage.removeItem('basic-info');
    }

    public setBasicInfo(basicInfo: IBasicInfo) {
        localStorage.setItem("basic-info", JSON.stringify(basicInfo));
        this.restaurantBasicInfo.next(basicInfo);
    }

    /**
     * get basic info from api
     * @returns 
     */ 
    public getBasicInfoFromAPI(): Observable<IBasicInfo> {
        return this.restaurantInfoService.getBasicInfo();
    }

}