import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TokenService } from 'src/app/core/auth/token.service';
import { GlobalAuthResolver } from '../global-auth/global-auth.resolver';
import { KdsResolver } from '../kds/kds.resolver';
import { RestaurantInfoResolver } from '../restaurantInfo/restaurant-info.resolver';
import { StoreResolver } from '../store/store.resolver';
import { TenantResolver } from '../tenant/tenant.resolver';

@Injectable({
  providedIn: 'root'
})
export class LogoutResolver {


  constructor(
    private _restaurantInfoResolver: RestaurantInfoResolver,
    private _tenantResolver: TenantResolver,
    private tokenService: TokenService,
    private navCtrl: NavController,
    private _globalAuthResolver: GlobalAuthResolver,
    private _storeResolver: StoreResolver,
    private _kdsResolver: KdsResolver,
    private router: Router
  ) {

  }



  /**
   * logout
   */
  public logout() {
    if (this.router.url.startsWith("/auth")) {
      return;
    }
    this.tokenService.clearToken();
    this._globalAuthResolver.loggedIn.next(false);
    this._tenantResolver.clearTenantName();
    this._restaurantInfoResolver.clearBasicInfo();
    this._storeResolver.clear();
    this._kdsResolver.selectedRoute.next(null);
    localStorage.removeItem('store');
    localStorage.removeItem('username');
    localStorage.removeItem('user');
    this.navCtrl.navigateForward('/auth');
  }

}
